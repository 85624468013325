import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BlockCutter } from '../../../components/BlockCutter'
import { Button } from '../../../components/Button'
import { ZENDESK_URL } from '../../../data'
import { Color, Font, getFont, mediaBreakpoint } from '../../../theme'

const FAQ = styled.div`
	display: flex;
	flex-direction: column;

	@media ${mediaBreakpoint.small} {
		flex-direction: column;
		padding: 0 10px;

		> *:not(:first-child) {
			margin-top: 40px;
		}

		.question {
			&__title {
				${getFont(Font.MOBILE_B1_BOLD)};
				color: ${Color.AT_BLACK_TEXT};
			}

			&__text {
				${getFont(Font.MOBILE_B2_REGULAR)};
				color: ${Color.AT_BLACK_2};
			}
		}

		.further-questions {
			${getFont(Font.MOBILE_H1_BOLD)};
			color: ${Color.AT_BLACK_TEXT};
		}
	}

	@media ${mediaBreakpoint.mediumPlus} {
		padding: 0 68px;

		.question {
			margin-bottom: 20px;

			&__title {
				font-family: Manrope;
				font-size: 23px;
				font-style: normal;
				font-weight: 700;
				line-height: 33px;
				letter-spacing: 0.01em;

				color: ${Color.AT_BLACK_TEXT};
			}

			&__text {
				font-family: Manrope;
				font-size: 21px;
				font-style: normal;
				font-weight: 400;
				line-height: 35px;
				letter-spacing: 0.01em;

				color: ${Color.AT_BLACK_2};
			}
		}

		.further-questions {
			margin: 20px 0;
			${getFont(Font.H3_BOLD)};
			color: ${Color.AT_BLACK_TEXT};
		}
	}
`

const Question = ({ text, title }: { text: string; title: string }) => (
	<div className="question">
		<div className="question__title">{title}</div>
		<div className="question__text">{text}</div>
	</div>
)

const PricingViewSection5FrequentlyAskedQuestions = () => {
	const { t } = useTranslation()

	return (
		<BlockCutter
			style={{ paddingTop: 0 }}
			title={{
				color: Color.AT_BLACK_NOT_1,
				lineColor: Color.AT_GREEN_1,
				text: t('views.pricing.sections.faq.title'),
			}}
		>
			<FAQ>
				<Question
					text={t('views.pricing.sections.faq.question1.text')}
					title={t('views.pricing.sections.faq.question1.title')}
				/>
				<Question
					text={t('views.pricing.sections.faq.question2.text')}
					title={t('views.pricing.sections.faq.question2.title')}
				/>
				<Question
					text={t('views.pricing.sections.faq.question3.text')}
					title={t('views.pricing.sections.faq.question3.title')}
				/>
				<Question
					text={t('views.pricing.sections.faq.question4.text')}
					title={t('views.pricing.sections.faq.question4.title')}
				/>
				<Question
					text={t('views.pricing.sections.faq.question5.text')}
					title={t('views.pricing.sections.faq.question5.title')}
				/>
				<div className="further-questions">
					{t('views.pricing.sections.faq.furtherQuestions')}
				</div>
				<a
					href={ZENDESK_URL}
					rel="noopener noreferrer"
					style={{ width: 'max-content' }}
					target="_blank"
				>
					<Button
						label={t('views.pricing.sections.faq.toHelpSection')}
						type="yellow"
						onClick={() => undefined}
					/>
				</a>
			</FAQ>
		</BlockCutter>
	)
}

export { PricingViewSection5FrequentlyAskedQuestions }
