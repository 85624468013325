import React from 'react'
import styled from 'styled-components'

import { Color } from '../../theme'

const FieldTextAreaStyle = styled.div`
	width: 100%;

	textarea {
		box-sizing: border-box;
		width: 100%;
		box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.12);
		border-radius: 3px;
		border: 0;
		padding: 10px 15px;
		background-color: #fff;
		resize: none;

		::placeholder {
			color: ${Color.AT_BLACK_1};
			opacity: 0.4;
		}

		:focus {
			outline: none;
		}
	}
`

const FieldTextArea = ({
	placeholder,
	setValue,
	value,
}: {
	placeholder: string
	setValue(newValue: string | null): void
	value: string | null
}) => (
	<FieldTextAreaStyle>
		<textarea
			rows={5}
			value={value ?? ''}
			onChange={(e) => setValue(e.target.value === '' ? null : e.target.value)}
			{...{ placeholder }}
		/>
	</FieldTextAreaStyle>
)

export { FieldTextArea }
