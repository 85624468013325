import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import { Color } from '../../theme'

const FieldTextStyle = styled.div<{ isDataValid: boolean }>`
	position: relative;
	width: 100%;

	input {
		box-sizing: border-box;
		width: 100%;
		border-radius: 6px;
		position: relative;
		margin: 5px;
		line-height: 3ex;
		padding-left: 10px;
		border: ${(props) =>
			props.isDataValid
				? `1px solid #B8C6CC`
				: `1px solid ${Color.WARNING_RED}`};
		padding: 10px 15px;
		background-color: #fff;

		::placeholder {
			color: ${Color.AT_BLACK_1};
			opacity: 0.4;
		}

		:focus {
			outline: none;
		}
	}

	label {
		position: absolute;
		top: 0.2ex;
		z-index: 1;
		left: 2em;
		background-color: white;
		border-radius: 6px;
		padding: 0 5px;
		color: #6c828b;
		font-size: 10px;
		line-height: 132%;
		letter-spacing: 0.03em;
		overflow: hidden;
		white-space: nowrap;
	}

	.validation-error {
		margin-top: 5px;
		margin-left: 10px;

		color: ${Color.WARNING_RED};
	}
`

const FieldText = ({
	placeholder,
	setValue,
	validationError,
	validator,
	value,
}: {
	placeholder: string
	setValue: (newValue: string | null) => void
	value: string | null
	validator?: (value: string) => boolean
	validationError?: JSX.Element | string
}) => {
	const [inputWasTouched, setInputWasTouched] = useState(false)

	const isDataValid = useCallback(() => {
		if (inputWasTouched && value && validator) {
			return validator(value)
		}
		return true
	}, [validator, value, inputWasTouched])

	return (
		<FieldTextStyle isDataValid={isDataValid()}>
			<label>{placeholder}</label>
			<input
				size={1}
				type="text"
				value={value ?? ''}
				onChange={(e) => {
					setValue(e.target.value === '' ? null : e.target.value)
					setInputWasTouched(true)
				}}
				{...{ placeholder }}
			/>
			{isDataValid() || (
				<div className="validation-error">{validationError}</div>
			)}
		</FieldTextStyle>
	)
}

export { FieldText }
