import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useFieldValidators } from '../hooks/use-field-validators'
import { blogsApi } from '../utilities/axios-api'

import { Button } from './Button'
import { CommentTextArea } from './fields/CommentTextArea'
import { FieldSingleSelect } from './fields/PriceSingleSelect'
import { FieldText } from './fields/PriceText'
import { FormFeedbackModal } from './FormFeedbackModal'
import { Modal } from './Modal'

type ZendeskRequest = {
	email: string
	/**
	 * comments
	 */
	kommentare: string
	packet: string
	phoneNumber: string
	numberOfParticipants: string
	numberOfDrones: string
	droneType: string
	period: string
}

const OrderingModalStyle = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	> *:not(:first-child) {
		margin-top: 10px;
	}

	Button {
		width: 100%;
		border-radius: 6px;
	}

	.drone-input-fields {
		display: flex;
		width: 100%;
		gap: 5%;
	}
`

const OrderingModal = ({
	activeTab,
	onClose,
	packageOptions,
	packageSelectPlaceholder,
	selectedTypeValue,
	title,
}: {
	onClose(): void
	packageOptions: Array<{
		label: string
		value: string
	}>
	packageSelectPlaceholder: string
	title: string
	selectedTypeValue?: string | null
	activeTab?: number | null
}) => {
	const { t } = useTranslation()

	const [email, setEmail] = useState<string | null>(null)
	const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
	const [comment, setComment] = useState<string>('')
	const [type, setType] = useState<string | null>(selectedTypeValue ?? null)
	const [numberOfParticipants, setNumberOfParticipants] = useState<
		string | null
	>(null)
	const [numberOfDrones, setNumberOfDrones] = useState<string | null>(null)

	const modalTitleTranslation = t(
		'views.pricing.sections.dronePackage.modal.title',
	)

	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
	const [isWaitingOnServer, setIsWaitingOnServer] = useState(false)
	const [isOrderSuccessful, setIsOrderSuccessful] = useState<boolean | null>(
		null,
	)
	const { validateE164PhoneNumber, validateEmail } = useFieldValidators()

	const numberOptions = [
		{
			label: '1',
			value: '1',
		},
		{
			label: '2',
			value: '2',
		},
		{
			label: '3',
			value: '3',
		},
		{
			label: '4',
			value: '4',
		},
		{
			label: '5',
			value: '5',
		},
		{
			label: '6',
			value: '6',
		},
		{
			label: '7',
			value: '7',
		},
		{
			label: '8',
			value: '8',
		},
		{
			label: '9',
			value: '9',
		},
		{
			label: '10',
			value: '10',
		},
		{
			label: '11',
			value: '11',
		},
		{
			label: '12',
			value: '12',
		},
		{
			label: '13',
			value: '13',
		},
		{
			label: '14',
			value: '14',
		},
		{
			label: '15',
			value: '15',
		},
		{
			label: '16',
			value: '16',
		},
		{
			label: '17',
			value: '17',
		},
		{
			label: '18',
			value: '18',
		},
		{
			label: '19',
			value: '19',
		},
		{
			label: '20',
			value: '20',
		},
	]

	const isFormValid = useCallback(() => {
		if (title === modalTitleTranslation) {
			return (
				numberOfParticipants !== null &&
				numberOfDrones !== null &&
				email !== null &&
				validateEmail(email) &&
				type !== null &&
				phoneNumber !== null &&
				validateE164PhoneNumber(phoneNumber)
			)
		}
		return (
			email !== null &&
			validateEmail(email) &&
			type !== null &&
			phoneNumber !== null &&
			validateE164PhoneNumber(phoneNumber)
		)
	}, [
		modalTitleTranslation,
		numberOfParticipants,
		numberOfDrones,
		email,
		type,
		phoneNumber,
		validateEmail,
		validateE164PhoneNumber,
	])

	const submit = async () => {
		let data: ZendeskRequest
		if (email === null || type === null || phoneNumber === null)
			throw new Error('missing data')
		const droneType = ''
		const period =
			activeTab === 0
				? 'monthly'
				: activeTab === 1
				? 'yearly'
				: activeTab === 2
				? 'biyearly'
				: ''
		if (title === modalTitleTranslation) {
			if (numberOfParticipants === null || numberOfDrones === null) {
				throw new Error('missing data')
			} else {
				data = {
					droneType,
					email,
					kommentare: comment,
					numberOfDrones,
					numberOfParticipants,
					packet: type,
					phoneNumber,
					period,
				}
			}
		} else {
			data = {
				droneType,
				email,
				kommentare: comment,
				numberOfDrones: '',
				numberOfParticipants: '',
				packet: type,
				phoneNumber,
				period,
			}
		}

		await blogsApi.post('/api/zendesk-requests/', data)
	}

	const submitTrySubscription = async () => {
		setIsFeedbackModalOpen(true)
		setIsWaitingOnServer(true)
		try {
			await submit()
			setIsOrderSuccessful(true)
		} catch (e) {
			setIsOrderSuccessful(false)
		}
		setIsWaitingOnServer(false)
	}

	const closeFeedbackModal = () => {
		setIsFeedbackModalOpen(false)
		if (isOrderSuccessful) onClose()
	}

	const submitButton = (type?.includes('Fast Fusion') && (
		<Button
			disabled={!isFormValid() || isWaitingOnServer}
			label={t('views.pricing.pricingCard.requestFastFusion')}
			type="gray"
			onClick={submitTrySubscription}
		/>
	)) ||
		(type?.includes('Pro Fusion') && (
			<Button
				disabled={!isFormValid() || isWaitingOnServer}
				label={t('views.pricing.pricingCard.requestProFusion')}
				type="yellow"
				onClick={submitTrySubscription}
			/>
		)) ||
		(type?.includes('Max Fusion') && (
			<Button
				disabled={!isFormValid() || isWaitingOnServer}
				label={t('views.pricing.pricingCard.requestMaxFusion')}
				type="darkgreen"
				onClick={submitTrySubscription}
			/>
		)) || (
			<Button
				disabled={!isFormValid() || isWaitingOnServer}
				label={t('views.pricing.pricingCard.requestThermalFusion')}
				type="purple"
				onClick={submitTrySubscription}
			/>
		)

	const selectDroneInputFields = (
		<>
			{' '}
			<FieldSingleSelect
				options={numberOptions}
				placeholder={t('views.dronePackages.modal.numberOfParticipants')}
				required
				setValue={setNumberOfParticipants}
				value={numberOfParticipants}
			/>
			<div className="drone-input-fields">
				<FieldSingleSelect
					options={numberOptions}
					placeholder={t('views.dronePackages.modal.numberOfDrones')}
					required
					setValue={setNumberOfDrones}
					value={numberOfDrones}
				/>
			</div>
		</>
	)

	return (
		<Modal title={title} {...{ onClose }}>
			{isFeedbackModalOpen && (
				<FormFeedbackModal
					isLoading={isWaitingOnServer}
					message={
						isOrderSuccessful
							? t('views.dronePackages.modal.formFeedback.success.message')
							: t('views.dronePackages.modal.formFeedback.failure.message')
					}
					title={
						isOrderSuccessful
							? t('views.dronePackages.modal.formFeedback.success.title')
							: t('views.dronePackages.modal.formFeedback.failure.title')
					}
					onClose={closeFeedbackModal}
				/>
			)}
			<OrderingModalStyle>
				{!activeTab && activeTab !== 0 && (
					<FieldSingleSelect
						options={packageOptions}
						placeholder={packageSelectPlaceholder}
						required
						setValue={setType}
						value={type}
					/>
				)}
				<FieldText
					placeholder={t('views.dronePackages.modal.email')}
					setValue={setEmail}
					value={email}
				/>
				<FieldText
					placeholder={t('views.dronePackages.modal.phone')}
					setValue={setPhoneNumber}
					validationError={t(
						'views.dronePackages.modal.phoneNotInInternationalFormatValidatinError',
					)}
					validator={validateE164PhoneNumber}
					value={phoneNumber}
				/>
				{title === modalTitleTranslation ? selectDroneInputFields : null}
				<CommentTextArea
					placeholder={t('views.dronePackages.modal.comment')}
					setValue={setComment}
					value={comment}
				/>
				{submitButton}
			</OrderingModalStyle>
		</Modal>
	)
}

export { OrderingModal }
