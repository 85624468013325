import React from 'react'
import styled from 'styled-components'

import { Color } from '../../theme'

const CommentTextAreaStyle = styled.div`
	width: 100%;
	position: relative;

	textarea {
		box-sizing: border-box;
		width: 100%;
		border: 1px solid #b8c6cc;
		border-radius: 6px;
		padding: 10px 15px;
		margin: 10px;
		line-height: 4ex;
		background-color: #fff;
		resize: none;

		::placeholder {
			color: ${Color.AT_BLACK_1};
			opacity: 0.4;
		}

		:focus {
			outline: none;
		}
	}

	label {
		position: absolute;
		top: 0.2ex;
		z-index: 1;
		left: 2em;
		background-color: white;
		border-radius: 6px;
		padding: 0 5px;
		color: #6c828b;
		overflow: hidden;
		white-space: nowrap;
		font-size: 12px;
		line-height: 162%;
		letter-spacing: 0.03em;
	}
`

const CommentTextArea = ({
	placeholder,
	setValue,
	value,
}: {
	placeholder: string
	setValue(newValue: string): void
	value: string
}) => (
	<CommentTextAreaStyle>
		<label>{placeholder}</label>
		<textarea
			rows={5}
			value={value ?? ''}
			onChange={(e) => setValue(e.target.value)}
			{...{ placeholder }}
		/>
	</CommentTextAreaStyle>
)

export { CommentTextArea }
